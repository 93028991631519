.signinmobile__popup__containerImgQuest {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: end;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 20px; /* 20px */
  z-index: 3;
}

.signinmobile__popup__containerImgQuest__popup {
  background: #0077FF;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 20px 20px 0 0;
  width: 100vw;
  height: 257px;
}

/* Применение анимации при открытии */
.signinmobile__popup__containerImgQuest.fade-in {
  animation: fadeIn 0.7s forwards;
}

.signinmobile__popup__containerImgQuest.fade-out {
  animation: fadeOut 0.7s forwards;
}

/* Применение анимации для окна */
.signinmobile__popup__containerImgQuest__popup.slide-up {
  animation: slideUp 0.7s forwards;
}

.signinmobile__popup__containerImgQuest__popup.slide-down {
  animation: slideDown 0.7s forwards;
}

.signinmobile__popup__containerImgQuest__popup__containerText {
  width: 319px;
}

.signinmobile__popup__containerImgQuest__popup__containerText__title {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 20px;
  color: #fff;
}

.signinmobile__popup__containerImgQuest__popup__containerText__subtitle {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
  margin-top: 6px;
}

.signinmobile__popup__containerImgQuest__popup__containerBtn__btn {
  cursor: pointer;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 14px;
  color: #fff;
  width: 147px;
  height: 25px;
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.signinmobile__popup__containerImgQuest__popup__containerBtn__btn__img {
  width: 25px;
  height: 25px;
}
