body {
  background: #0077FF;
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


/* background of the scrollbar except button or resizer */
*::-webkit-scrollbar-track {
  background-color:transparent;
}

/* scrollbar itself */
*::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
}

/* set button(top and bottom of the scrollbar) */
*::-webkit-scrollbar-button {
  display:none;
}

:root {
  --teal-dark-1: #0d1514;
  --teal-dark-2: #121c1b;
  --teal-dark-3: #0d2d2a;
  --teal-dark-4: #043b35;
  --teal-dark-5: #0b4841;
  --teal-dark-6: #16574f;
  --teal-dark-7: #1d6960;
  --teal-dark-8: #207e73;
  --teal-dark-9: #12a594;
  --teal-dark-10: #0eb39e;
  --teal-dark-11: #0bd8b6;
  --teal-dark-12: #adf0dd;
  --jade-dark-1: #0d1512;
  --jade-dark-2: #121c18;
  --jade-dark-3: #0f2e22;
  --jade-dark-4: #0b3b2c;
  --jade-dark-5: #114837;
  --jade-dark-6: #1b5745;
  --jade-dark-7: #246854;
  --jade-dark-8: #2a7e68;
  --jade-dark-9: #29a383;
  --jade-dark-10: #27b08b;
  --jade-dark-11: #1fd8a4;
  --jade-dark-12: #adf0d4;
}

:root {
  --font-family: "Geologica", sans-serif;
  --second-family: "Roboto", sans-serif;
  --third-family: "Jost", sans-serif;
}

* {
  padding: 0;
  margin: 0;
  border: none;
  text-decoration: none;
  font-family: var(--font-family);
  box-sizing: border-box;
  outline: none;
  background: transparent;
}

.disabled {
  overflow: hidden;
  opacity: 0;
  display: none;
  visibility: hidden;
}

button {
  cursor: pointer;
}

button:hover {
  opacity: 0.8;
}

a:hover{
  opacity: 0.8;
}

.red{
  background: #a83939;
}

.yellow{
  background: #a89d39;
}

.green{
  background: #4fa839;
}
@media screen and (min-width: 1920px) {
}

@media screen and (min-width: 1280px) and (max-width: 1919px) {

}

@media screen and (min-width: 1024px) and (max-width: 1279px) {

}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  
}

