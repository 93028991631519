.adminpopupperson {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  opacity: 0;
  transition: opacity 0.8s ease;
}

.adminpopupperson.in {
  opacity: 1;
}

.adminpopupperson.out {
  opacity: 0;
}

.adminpopupperson__popup {
  border-radius: 20px;
  background: #0077FF;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.adminpopupperson__popup__header {
  display: flex;
  justify-content: space-between;
}

.adminpopupperson__popup__header__title {
  font-family: var(--font-family);
  font-weight: 500;
  color: #fff;
}

.adminpopupperson__popup__header__btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.adminpopupperson__popup__header__btn__span {
  font-family: var(--font-family);
  font-weight: 500;
  color: #fff;
}

.adminpopupperson__popup__inputs {
  display: flex;
  justify-content: space-between;
}

.adminpopupperson__popup__inputs__input {
  border-radius: 140px;
  background: #fff;
  color: #0077FF;
}

.adminpopupperson__popup__inputs__btn {
  border-radius: 140px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.adminpopupperson__popup__inputs__btn__span {
  font-family: var(--font-family);
  font-weight: 500;
  color: #0077FF;
}
.adminpopupperson__popup__cards {
  overflow-y: scroll;
}

.adminpopupperson__popup__cards table {
  border-collapse: collapse;
}

.adminpopupperson__popup__cards table thead th {
  font-family: var(--font-family);
  font-weight: 500;
  color: rgba(255, 255, 255, 0.3);
  text-align: left;
  border-bottom: 2px solid rgba(217, 217, 217, 0.1);
}

.adminpopupperson__popup__cards table thead th:last-of-type {
  text-align: right;
}

.adminpopupperson__popup__cards table tbody tr td:nth-child(1) {
  font-family: var(--font-family);
  font-weight: 500;
  color: #fff;
  padding: 0; /* Убираем паддинг */
  line-height: 30px; /* Устанавливаем высоту строки текста */
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; /* Запретить перенос строки */
}
.adminpopupperson__popup__cards table tbody tr td:nth-child(2) {
  font-family: var(--font-family);
  height: 30px;
  font-weight: 500;
  color: #fff;
  line-height: 30px;
  padding: 0;
}

.adminpopupperson__popup__cards table tbody tr td:nth-child(3) {
  text-align: right;
}

.adminpopupperson__popup__cards table tbody tr td:nth-child(3) button {
  text-align: right;
  display: inline-block;
  padding: 0;
}

.adminpopupperson__popup__cards table tbody tr td:nth-child(3) button img {
  vertical-align: middle;
}

.adminpopupperson__popup__cards table tbody tr td:nth-child(3) button span {
  vertical-align: middle;
  font-family: var(--font-family);
  font-weight: 500;
  color: #fff;
}

.adminpopupperson__popup__cards
  table
  tbody
  tr
  td:nth-child(3)
  button:last-of-type
  span {
  vertical-align: middle;
  font-family: var(--font-family);
  font-weight: 500;
  color: #e74b4b;
}

.for728 {
  display: none;
}

@media screen and (min-width: 1920px) {
  .adminpopupperson__popup {
    width: 1139px;
    height: 676px;
  }

  .adminpopupperson__popup__header {
    width: 1079px;
    height: 18px;
    margin-top: 27px;
  }

  .adminpopupperson__popup__header__title {
    font-size: 14px;
  }

  .adminpopupperson__popup__header__btn {
    width: 147px;
    height: 24px;
  }

  .adminpopupperson__popup__header__btn__img {
    width: 24px;
    height: 24px;
  }

  .adminpopupperson__popup__header__btn__span {
    font-size: 14px;
  }

  .adminpopupperson__popup__inputs {
    width: 1079px;
    height: 50px;
    margin-top: 27px;
  }

  .adminpopupperson__popup__inputs__input {
    width: 716px;
    height: 50px;
    padding-left: 30px;
  }

  .adminpopupperson__popup__inputs__btn {
    width: 234px;
    height: 50px;
  }

  .adminpopupperson__popup__inputs__btn__img {
    width: 30px;
    height: 30px;
  }

  .adminpopupperson__popup__inputs__btn__span {
    font-size: 14px;
    margin-left: 10px;
  }
  .adminpopupperson__popup__cards {
    width: 1079px;
    margin-top: 30px;
    max-height: 516px;
  }

  .adminpopupperson__popup__cards table {
    width: 1079px;
    max-height: 516px;
  }

  .adminpopupperson__popup__cards table thead {
    width: 1079px;
  }

  .adminpopupperson__popup__cards table thead tr {
    width: 1079px;
  }

  .adminpopupperson__popup__cards table thead th {
    font-size: 14px;
    padding-bottom: 16px;
  }

  .adminpopupperson__popup__cards table thead th:first-of-type {
    width: 200px;
  }

  .adminpopupperson__popup__cards table thead th:nth-child(2) {
    width: 174px;
  }

  .adminpopupperson__popup__cards table tbody {
    width: 1079px;
  }

  .adminpopupperson__popup__cards table tbody tr {
    width: 1079px;
    height: 30px;
    margin-top: 10px;
  }

  .adminpopupperson__popup__cards table tbody tr td:nth-child(1) {
    font-size: 14px;
    width: 200px; /* Устанавливаем ширину */
    height: 30px; /* Устанавливаем высоту */
  }
  .adminpopupperson__popup__cards table tbody tr td:nth-child(2) {
    height: 30px;
    font-size: 14px;
    line-height: 30px;
  }

  .adminpopupperson__popup__cards table tbody tr td:nth-child(3) button {
    height: 30px;
    margin-right: 11px;
  }

  .adminpopupperson__popup__cards
    table
    tbody
    tr
    td:nth-child(3)
    button:nth-child(3) {
    margin-right: 0;
  }

  .adminpopupperson__popup__cards table tbody tr td:nth-child(3) button img {
    width: 30px;
    height: 30px;
  }

  .adminpopupperson__popup__cards table tbody tr td:nth-child(3) button span {
    width: 30px;
    height: 30px;
    font-size: 14px;
    margin-left: 10px;
  }

  .adminpopupperson__popup__cards
    table
    tbody
    tr
    td:nth-child(3)
    button:last-of-type
    span {
    width: 30px;
    height: 30px;
    font-size: 14px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1919px) {
  .adminpopupperson__popup {
    width: 1139px;
    height: 676px;
  }

  .adminpopupperson__popup__header {
    width: 1079px;
    height: 18px;
    margin-top: 27px;
  }

  .adminpopupperson__popup__header__title {
    font-size: 14px;
  }

  .adminpopupperson__popup__header__btn {
    width: 147px;
    height: 24px;
  }

  .adminpopupperson__popup__header__btn__img {
    width: 24px;
    height: 24px;
  }

  .adminpopupperson__popup__header__btn__span {
    font-size: 14px;
  }

  .adminpopupperson__popup__inputs {
    width: 1079px;
    height: 50px;
    margin-top: 27px;
  }

  .adminpopupperson__popup__inputs__input {
    width: 716px;
    height: 50px;
    padding-left: 30px;
  }

  .adminpopupperson__popup__inputs__btn {
    width: 234px;
    height: 50px;
  }

  .adminpopupperson__popup__inputs__btn__img {
    width: 30px;
    height: 30px;
  }

  .adminpopupperson__popup__inputs__btn__span {
    font-size: 14px;
    margin-left: 10px;
  }
  .adminpopupperson__popup__cards {
    width: 1079px;
    margin-top: 30px;
    max-height: 516px;
  }

  .adminpopupperson__popup__cards table {
    width: 1079px;
    max-height: 516px;
  }

  .adminpopupperson__popup__cards table thead {
    width: 1079px;
  }

  .adminpopupperson__popup__cards table thead tr {
    width: 1079px;
  }

  .adminpopupperson__popup__cards table thead th {
    font-size: 14px;
    padding-bottom: 16px;
  }

  .adminpopupperson__popup__cards table thead th:first-of-type {
    width: 200px;
  }

  .adminpopupperson__popup__cards table thead th:nth-child(2) {
    width: 174px;
  }

  .adminpopupperson__popup__cards table tbody {
    width: 1079px;
  }

  .adminpopupperson__popup__cards table tbody tr {
    width: 1079px;
    height: 30px;
    margin-top: 10px;
  }

  .adminpopupperson__popup__cards table tbody tr td:nth-child(1) {
    font-size: 14px;
    width: 200px; /* Устанавливаем ширину */
    height: 30px; /* Устанавливаем высоту */
  }
  .adminpopupperson__popup__cards table tbody tr td:nth-child(2) {
    height: 30px;
    font-size: 14px;
    line-height: 30px;
  }

  .adminpopupperson__popup__cards table tbody tr td:nth-child(3) button {
    height: 30px;
    margin-right: 11px;
  }

  .adminpopupperson__popup__cards
    table
    tbody
    tr
    td:nth-child(3)
    button:nth-child(3) {
    margin-right: 0;
  }

  .adminpopupperson__popup__cards table tbody tr td:nth-child(3) button img {
    width: 30px;
    height: 30px;
  }

  .adminpopupperson__popup__cards table tbody tr td:nth-child(3) button span {
    width: 30px;
    height: 30px;
    font-size: 14px;
    margin-left: 10px;
  }

  .adminpopupperson__popup__cards
    table
    tbody
    tr
    td:nth-child(3)
    button:last-of-type
    span {
    width: 30px;
    height: 30px;
    font-size: 14px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .adminpopupperson__popup {
    width: 999px;
    height: 676px;
  }

  .adminpopupperson__popup__header {
    width: 939px;
    height: 18px;
    margin-top: 27px;
  }

  .adminpopupperson__popup__header__title {
    font-size: 14px;
  }

  .adminpopupperson__popup__header__btn {
    width: 147px;
    height: 24px;
  }

  .adminpopupperson__popup__header__btn__img {
    width: 24px;
    height: 24px;
  }

  .adminpopupperson__popup__header__btn__span {
    font-size: 14px;
  }

  .adminpopupperson__popup__inputs {
    width: 939px;
    height: 50px;
    margin-top: 27px;
  }

  .adminpopupperson__popup__inputs__input {
    width: 695px;
    height: 50px;
    padding-left: 30px;
  }

  .adminpopupperson__popup__inputs__btn {
    width: 234px;
    height: 50px;
  }

  .adminpopupperson__popup__inputs__btn__img {
    width: 30px;
    height: 30px;
  }

  .adminpopupperson__popup__inputs__btn__span {
    font-size: 14px;
    margin-left: 10px;
  }
  .adminpopupperson__popup__cards {
    width: 939px;
    margin-top: 30px;
    max-height: 516px;
  }

  .adminpopupperson__popup__cards table {
    width: 939px;
    max-height: 516px;
  }

  .adminpopupperson__popup__cards table thead {
    width: 939px;
  }

  .adminpopupperson__popup__cards table thead tr {
    width: 939px;
  }

  .adminpopupperson__popup__cards table thead th {
    font-size: 14px;
    padding-bottom: 16px;
  }

  .adminpopupperson__popup__cards table thead th:first-of-type {
    width: 156px;
  }

  .adminpopupperson__popup__cards table thead th:nth-child(2) {
    width: 174px;
  }

  .adminpopupperson__popup__cards table tbody {
    width: 939px;
  }

  .adminpopupperson__popup__cards table tbody tr {
    width: 939px;
    height: 30px;
    margin-top: 10px;
  }

  .adminpopupperson__popup__cards table tbody tr td:nth-child(1) {
    font-size: 14px;
    width: 156px; /* Устанавливаем ширину */
    height: 30px; /* Устанавливаем высоту */
  }
  .adminpopupperson__popup__cards table tbody tr td:nth-child(2) {
    height: 30px;
    font-size: 14px;
    line-height: 30px;
  }

  .adminpopupperson__popup__cards table tbody tr td:nth-child(3) button {
    height: 30px;
    margin-right: 11px;
  }

  .adminpopupperson__popup__cards
    table
    tbody
    tr
    td:nth-child(3)
    button:nth-child(3) {
    margin-right: 0;
  }

  .adminpopupperson__popup__cards table tbody tr td:nth-child(3) button img {
    width: 30px;
    height: 30px;
  }

  .adminpopupperson__popup__cards table tbody tr td:nth-child(3) button span {
    width: 30px;
    height: 30px;
    font-size: 14px;
    margin-left: 10px;
  }

  .adminpopupperson__popup__cards
    table
    tbody
    tr
    td:nth-child(3)
    button:last-of-type
    span {
    width: 30px;
    height: 30px;
    font-size: 14px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  @keyframes popupSlideIn {
    from {
      margin-bottom: -284px;
    }
    to {
      margin-bottom: 0;
    }
  }

  @keyframes popupSlideOut {
    from {
      margin-bottom: 0;
    }
    to {
      margin-bottom: -284px;
    }
  }

  @keyframes personSlideIn {
    from {
      margin-top: 100vh;
    }
    to {
      margin-top: 0;
    }
  }

  @keyframes personSlideOut {
    from {
      margin-top: 0;
    }
    to {
      margin-top: 100vh;
    }
  }

  @keyframes OpIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes OpOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  .for1024 {
    display: none;
  }
  .for728 {
    display: flex;
  }

  .adminpopupperson__popup {
    width: 100vw;
    height: 100vh;
    border-radius: 0;
  }

  .adminpopupperson__popup.in {
    animation: personSlideIn 0.8s ease-in-out forwards;
  }
  .adminpopupperson__popup.out {
    animation: personSlideIn 0.8s ease-in-out forwards;
  }

  .adminpopupperson__popup {
    width: 100vw;
    height: 100vh;
    border-radius: 0;
  }

  .adminpopupperson__popup__header {
    width: 727px;
    height: 18px;
    margin-top: 6px;
  }

  .adminpopupperson__popup__header__title {
    font-size: 14px;
  }

  .adminpopupperson__popup__header__btn {
    width: 147px;
    height: 24px;
  }

  .adminpopupperson__popup__header__btn__img {
    width: 24px;
    height: 24px;
  }

  .adminpopupperson__popup__header__btn__span {
    font-size: 14px;
  }

  .adminpopupperson__popup__inputs {
    width: 727px;
    height: 50px;
    margin-top: 15px;
  }

  .adminpopupperson__popup__inputs__input {
    width: 397px;
    height: 50px;
    padding-left: 30px;
  }

  .adminpopupperson__popup__inputs__btn {
    width: 320px;
    height: 50px;
  }

  .adminpopupperson__popup__inputs__btn__img {
    width: 30px;
    height: 30px;
  }

  .adminpopupperson__popup__inputs__btn__span {
    font-size: 14px;
    margin-left: 10px;
  }
  .adminpopupperson__popup__cards {
    width: 727px;
    margin-top: 15px;
    max-height: 487px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .adminpopupperson__popup__cards__err {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .adminpopupperson__popup__cards__err__text {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    color: #fff;
  }

  .adminpopupperson__popup__cards__card {
    position: relative;
    width: 354px;
    height: 26px;
    display: flex;
    align-items: center;
  }

  .adminpopupperson__popup__cards__card__title {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    color: #fff;
    width: 98px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .adminpopupperson__popup__cards__card__title__bck {
    width: 98px;
    height: 26px;
    position: absolute;
    left: 0;
    background: linear-gradient(90deg, rgba(36, 36, 36, 0) 0%, #0077FF 100%);
  }

  .adminpopupperson__popup__cards__card__subtitle {
    border: 3px solid rgba(255, 255, 255, 0.302);
    border-radius: 60px;
    width: 41px;
    height: 26px;
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .adminpopupperson__popup__cards__card__btn {
    border-radius: 60px;
    width: 194px;
    height: 26px;
    background: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    color: #0077FF;
    margin-left: 11px;
  }

  .adminpopupperson__popup__popupmenu__bck {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    z-index: 6;
    display: flex;
    align-items: end;
    opacity: 0;
  }

  .adminpopupperson__popup__popupmenu__bck.in {
    animation: OpIn 0.8s ease-in-out forwards;
  }

  .adminpopupperson__popup__popupmenu__bck.out {
    animation: OpOut 0.8s ease-in-out forwards;
  }

  .adminpopupperson__popup__popupmenu {
    border-radius: 20px 20px 0 0;
    width: 100vw;
    height: 283px;
    background: #0077FF;
    display: flex;
    margin-bottom: -284px;
    flex-direction: column;
    position: relative;
  }
  .adminpopupperson__popup__popupmenu.in {
    animation: popupSlideIn 0.7s ease-in-out forwards;
  }

  .adminpopupperson__popup__popupmenu.out {
    animation: popupSlideOut 0.7s ease-in-out forwards;
  }

  .adminpopupperson__popup__popupmenu__login {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 25px;
    color: #fff;
    margin-left: 20px;
    margin-top: 42px;
  }
  .adminpopupperson__popup__popupmenu__tip {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 15px;
    color: #fff;
    margin-left: 20px;
  }

  .adminpopupperson__popup__popupmenu__btn {
    width: 225px;
    height: 25px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    margin-left: 20px;
  }

  .adminpopupperson__popup__popupmenu__btn__img {
    height: 25px;
    width: 25px;
  }

  .adminpopupperson__popup__popupmenu__btn__span {
    margin-left: 8px;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    color: #fff;
  }

  .adminpopupperson__popup__popupmenu__btn:first-of-type {
    margin-top: 24px;
  }

  .adminpopupperson__popup__popupmenu__btn:nth-child(3)
    .adminpopupperson__popup__popupmenu__btn__span {
    color: #e74b4b;
  }

  .adminpopupperson__popup__popupmenu__btn__back {
    border-radius: 140px;
    width: 356px;
    height: 45px;
    background: #fff;
    margin-left: 20px;
    margin-top: 10px;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: #0077FF;
  }
}
