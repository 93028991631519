.main__err {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    border-radius: 1.04vw;
  }
  
  .main__err__title {
    color: #0077FF;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 20px; /* Примерно 20px на экране 1920px */
  }
  .main__err__back__btn {
    background: transparent;
    font-family: var(--font-family);
    color: #0077FF;
    position: absolute;
    display: flex;
    align-items: center;
    bottom: 20px;
    right: 20px;
  }